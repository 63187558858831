import React from "react";
import Container from 'react-bootstrap/Container';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function AcademicWork() {
    return(
        <Container fluid className="max-content-width">
            <h3 className="f-headline">Academic Work</h3>
            <br />
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'var(--concrete)',}}
                    contentArrowStyle={{ borderRight: '7px solid  var(--concrete)' }}
                    date="2024 - April"
                    iconStyle={{ background: 'var(--concrete)', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title" style={{color: "#fff"}}>Master's Thesis</h3>
                    <h5 className="vertical-timeline-element-subtitle" style={{color: "#fff"}}>Technische Hochschule OWL, Lemgo</h5>
                    <p style={{color: "#fff"}}>
                    Development and Validation of a Deployment Strategy for Digital Services Using Various Hardware Assets for Industrial Applications <br /><br />
                    <small>#devops #digitaltwin #I4.0AutoServ</small>
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2023 - August"
                    iconStyle={{ background: 'var(--dark-gray)', color: '#fff' }}

                >
                    <h3 className="vertical-timeline-element-title">Research Project</h3>
                    <h5 className="vertical-timeline-element-subtitle">Technische Hochschule OWL, Lemgo</h5>
                    <p>
                    Continuous Integration Pipelines for Data-Driven Services Supporting Different Hardware Architectures in the Field of Industry 4.0 <br /><br />
                    <small>#devops #cicd #emulation #docker #I4.0AutoServ</small>
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2021 - April"
                    iconStyle={{ background: 'var(--dark-gray)', color: '#fff' }}
                >
                    <h3 className="vertical-timeline-element-title">Bachelor's Thesis</h3>
                    <h5 className="vertical-timeline-element-subtitle">Leibniz Universität, Hannover</h5>
                    <p>
                    Domain adaptation for object recognition in aerial images <br /> <br />
                    <small>#machinelearning #objectrecognition #da-faster #faster-r-cnn</small>
                    </p>
                </VerticalTimelineElement>
   
                </VerticalTimeline>
                <br />
        </Container>
    )
}