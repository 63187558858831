import React from "react";
import Container from 'react-bootstrap/Container';
import vcard from '../img/contact_qr.png';
import portrait from "../img/pb_foster_new.jpg"

export default function DigitalVCard(){
    return(
        <Container fluid className="max-content-width">
            <br />
            <h3 align="center">Robin Foster</h3>
            <br />
            <p align="center"><img className="vportrait" src={portrait} alt="Robin Foster Portrait" title="Robin Foster Portrait" /></p>
            <br />
            <p align="center"><i>Contact Info</i></p>
            <p align="center" className="vcard"><a href="https://r-foster.de/foster.vcf" target="_blank" rel="noreferrer"><img src={vcard} alt="Digital Business Card" /></a></p>
        </Container>
    )
}