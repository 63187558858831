import React from "react";
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

export default function Blog() {
    return(
        <Container fluid className="max-content-width">
            <h3 className="f-headline">Blog</h3>
            <br />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Preamble</Accordion.Header>
                    <Accordion.Body>
                        Since I often encounter issues or interesting ideas related to self-hosting various services, I'd like to share them here for anyone who comes across them by chance.
                        <br />
                        <br />
                        Work in progress...
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>A Guide: When BibLatex won't work - ShareLatex v.5.0.1 Fix</Accordion.Header>
                    <Accordion.Body>
                    <p>This guide provides a solution to successfully run Overleaf ShareLatex v.5.0.1 without getting BibLatex errors.</p>
                    <p>First of all, please install ShareLatex like described in the <a rel="noreferrer" href="https://github.com/overleaf/toolkit/blob/master/doc/quick-start-guide.md" target="_blank">official guide</a>.</p>
                    <p>After installation connect to the ShareLatex container:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>docker exec -it sharelatex /bin/bash</textarea>
                    <br />
                    <br />
                    <p>If you want to, you can install optional latex packages with:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>tlmgr install scheme-full</textarea>
                    <br />
                    <br />
                    <p>You will encounter the following error message after running this command:</p>
                    <textarea style={{width: "100%"}} rows={4} readOnly>tlmgr: Local TeX Live (2023) is older than remote repository (2024).
                                                            Cross release updates are only supported with 
                                                            update-tlmgr-latest(.sh/.exe) --update 
                                                            See https://tug.org/texlive/upgrade.html for details.
                    </textarea>
                    <br />
                    <br />
                    <p>To resolve this error run the following commands:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>cd /usr/local/texlive/</textarea>
                    <textarea style={{width: "100%"}} rows={1} readOnly>cp -a 2023 2024</textarea>
                    <br />
                    <br />
                    <p>Download and install the new version of tlmgr:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>wget https://mirror.ctan.org/systems/texlive/tlnet/update-tlmgr-latest.sh</textarea>
                    <textarea style={{width: "100%"}} rows={1} readOnly>sh update-tlmgr-latest.sh -- --upgrade</textarea>
                    <textarea style={{width: "100%"}} rows={1} readOnly>tlmgr update --self --all</textarea>
                    <br />
                    <br />
                    <p>Clear the lua cache:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>luaotfload-tool -fu</textarea>
                    <br />
                    <br />
                    <p>Try to install the optional latex packages again (it should work now):</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>tlmgr install scheme-full</textarea>
                    <br />
                    <br />
                    <p><b>Important:</b> BibLatex (Biber backend) will now raise errors when trying to compile your documents. To fix BibLatex run:</p>
                    <textarea style={{width: "100%"}} rows={1} readOnly>ln /usr/local/texlive/2023/bin/x86_64-linux/biber /usr/local/bin/biber</textarea>
                    <br />
                    <br />
                    <p>After these steps, ShareLatex v.5.0.1 should be fully functional. Have fun!</p>
                    <br />
                    <br />
                    <i>Thanks to Leon for sharing this guide!</i>
                    
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Dockerized Caddy reverse proxy and the tale of IPv6</Accordion.Header>
                <Accordion.Body>
                    <p align="justify">
                    I recently encountered a problem that really got me thinking. I use a Caddy Reverse Proxy on my server, which is a really great piece of software. 
                    Coming from NGINX or Apache, you just have to adjust to thinking more simply at the beginning since hardly anything needs configuring. 
                    Anyway, behind my proxy, I had a service that logs the client IP for authenticated users. 
                    I noticed in the logs that strangely, the IP address being logged during my logins was that of the Docker Network Gateway, whereas for another user, it was their ISP's public IP.
                    After triple-checking that I wasn't connected to my server via VPN, I decided to use curl for debugging. 
                    With debug logging configured, Caddy indeed showed the Docker Gateway IP as the Remote IP even with a normal curl request. 
                    This led me to realize that it can be no issue with setting the X-Forwarded-For header.
                    The great thing is, Caddy automatically takes care of all the necessary headers; as I said, it's a fantastic piece of software. 
                    Just for fun, I ran curl with the <i style={{color: "gray"}}>--ipv4</i> flag and voila, my correct public IP appeared in the log.
                <br />
                <br />
                    With that, it was clear how the troubleshooting could proceed. Generally, I always set up an A (IPv4) and AAAA (IPv6) record for my domains.
                    This allows every device, as long as the ISP permits, to access the server using IPv6. But how does Docker handle IPv6 traffic in its default installation?

                    The answer is: initially, Docker doesn't handle IPv6 traffic internally. Docker performs Network Address Translation (NAT), buzzword: <b>userland-proxy</b>. 
                    From the container's perspective, all traffic appears to come from its network gateway.
                    </p>
                <br />
                <br />
                    The top 3 solutions:
                    <br />
                    <br />
                    <ol>
                        <li>For the container where IPv6 addresses need to be resolved correctly, you can simply set the networking to <i style={{color: "gray"}}>--network=host for Caddy.</i> This bypasses the userland proxy.</li>
                        <li>If a specific subdomain is intended to be used for the container, it's possible to delete the AAAA record. The client will still have access to the server because ISPs perform NAT64.
                            If the domain settings include a record like <i style={{color: "gray"}}>@ AAAA IP</i> to map all subdomains to a host, simply creating a <i style={{color: "gray"}}>subdomain A IP</i> record for that specific service is sufficient. 
                            DNS will then not resolve to the IPv6 address for all subdomains. This can also be verified using the Linux dig command.
                        </li>
                        <li>
                            The last solution is likely the most comprehensive. We enable IPv6 in Docker.
                            On <a href="https://github.com/caddyserver/caddy/issues/4339#issuecomment-1368681477" target="_blank" rel="noreferrer">GitHub</a> there is a really nice conversation about this topic.
                            I suggest taking a look at it.
                        </li>
                    </ol>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Why I switched to Linux and what I have learned over several years</Accordion.Header>
                <Accordion.Body>
                I can still remember standing in the Conrad electronics store about 10 years ago. That was the first time I saw a Raspberry Pi. 
                I had certainly read a lot about these little computers in magazines, but I hadn't yet got my hands on one. So the decision was made. I had to take it with me!
                <br />
                <br />
                Probably one of the best decisions I've ever made. 
                <br />
                <br />
                As an experienced Windows user, I think it was still Windows 7 at the time, I was familiar with the GUIs and knew almost every conceivable menu. 
                However, the command line of the Raspian (a Debian-based distribution; ancestor of the Raspberry Pi OS) was new to me. 
                It took years and several projects, some more, some less successful, until I got used to the black background with white characters. 
                Yes, I slowly started to like it, even if the beginning was certainly often frustrating. 
                <br />
                <br />
                Through gaming with friends, I eventually came up with the idea of hosting servers for various games myself or voice servers such as Teamspeak. 
                Of course, this worked great on a Windows server, but I quickly realized that hosters were less focused on this. So the plan was set: I would book my first own server.
                <br />
                <br />
                This was also one of the best decisions of my life. Sorry, I just enjoy technology.
                <br />
                <br />
                The first steps also involved a lot of practice. How do you actually secure a thing like this? How does it work with ports? 
                How do I manage my services? Or even more fundamentally: Which distribution do I choose? Well, this question didn't really arise for me. Thanks to Raspian, I was very familiar with Debian.  
                <br />
                <br />
                Years have passed, new servers have been booked, old ones shut down. Then came the topic of domains and finally, at some point, Docker.
                 I immediately loved the principle of containers and could hardly hold back from flattening my existing server and converting everything straight away.
                  My friends also jumped on the trend and somehow I live in a bubble with very satisfied Linux users and hobby server admins. 
                  <br />
                <br />
                Even though all the servers and IoT gadgets I had were now all running on Linux, I was still hanging on to my Windows for the desktop PC.
                 My first experience with a Linux with GUI was yet to come. My old laptop was a little weak at some point in the middle of my bachelor's degree and simply no longer provided me with the power I needed for my day-to-day work.
                  I read in a magazine about laptops that came with Ubuntu pre-installed instead of Windows. So it was clear: I was going to try it out.
                  <br />
                <br />
                That was the beginning of my move to Linux. I quickly learned that many of the functions I used on Windows were also available on Ubuntu. 
                LibreOffice instead of MSOffice, Gimp instead of Paint, etc. And I could customize everything the way I wanted. I could look deep into the system, learn how an OS works on deeper layers than just the GUI. It was fascinating.
                <br />
                <br />
                Gaming is a big part of my free time and has always come with a big caveat when it comes to Linux. 
                There were always reports here and there that something wasn't working or you had to tinker for hours until some games would run. 
                That held me back for years until I came across PopOS! It's a beautiful distribution that has become my daily driver. And that on all my devices. 
                In combination with Steam, Proton and Lutris, gaming was really no longer a problem. I also hardly have any driver problems, thanks to the strong Linux community. 
                Today, in 2024, it is possible to play many AAA games directly under Linux without any problems. Especially the Steam compatibility mode and Lutris are a great support. In most cases, installation is a one-click solution.  
                <br />
                <br />
                What I like most about Linux is simply the ability to tinker. Everyone can try out what they like and is free to make their own decisions.
                 If you have problems, there is an amazing community that provides tips and answers in various forums. So far there hasn't been a problem that I couldn't fix with a workaround or something like that.
                 I'm curious to see how the world behaves in relation to Linux in the next few years and whether more users will switch from Windows or MacOS.

                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
            <br />
            <br />
        </Container>
    )
    /*
                <Accordion.Item eventKey="4">
                <Accordion.Header>Todo</Accordion.Header>
                <Accordion.Body>

                </Accordion.Body>
            </Accordion.Item>
    */
}