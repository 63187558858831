import React from "react";
import Container from 'react-bootstrap/Container';
import Typewriter from 'typewriter-effect';
import portrait from "../img/pb_foster_new.jpg"

export default function Hello() {


    return(
        <Container fluid className="max-content-width">
            <div className="f-hello">
                <p>GREETINGS! I AM</p>
                
                <h2>Robin Foster</h2>

                <p><b>
                <Typewriter options={{loop: true}}
                onInit={(typewriter) => {
                    
                    typewriter.typeString('I\'m a technology enthusiast')
                    .pauseFor(500)
                    .deleteChars(21)
                    .typeString('software developer')
                    .pauseFor(500)
                    .deleteChars(18)
                    .typeString('researcher')
                    .pauseFor(500)
                    .deleteChars(10)
                    .typeString('kind of creative person')
                    .pauseFor(500)
                    .deleteChars(23)
                    .start();
                }}
                /></b></p>
                <img src={portrait} alt="Robin Foster Portrait" title="Robin Foster Portrait" />
                <hr />
                <p>Currently working as a <b>research associate</b> @ Institut für industrielle Informationstechnik (inIT)</p>
                            
            </div>
        </Container>
    )
}