import React from "react";
import Container from 'react-bootstrap/Container';

export default function Contact() {
    return(
        <Container fluid className="max-content-width">
            <br />
            <p align="center">You can contact me via mail: <a href="mailto:hello+websiterequest@r-foster.de">hello@r-foster.de</a></p>
            <p align="center">To use PGP, you can find my pubkey over <a href="/foster.crt"> here</a></p>
            <br />
            <p align="center">Or contact me via <a href="https://www.linkedin.com/in/robin-foster-a92a1a2b7/">LinkedIn</a></p>
        </Container>
    )
}