import { Routes, Route} from "react-router-dom";
import './style/main.css';
import FNavBar from './components/navbar';
import NoMatch from './components/forofour'
import Hello from "./components/Hello";
import AcademicWork from "./components/AcademicWork";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import DigitalVCard from "./components/DigitalVCard";


function App() {
  return (
    <div>
      <FNavBar />
      <br />
      <br />
        <Routes>
          <Route index element={<Hello />} />
          <Route path="academic" element={<AcademicWork />} />
          <Route path="blog" element={<Blog />} />
          <Route path="contact" element={<Contact />} />
          <Route path="digital-v-card" element={<DigitalVCard />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      <p className="footer">&copy; Robin Foster &middot; 2024</p>
    </div>
  );
}

export default App;
